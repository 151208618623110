
#about {
    display: grid;
    grid-template-columns: 2fr 5fr;
   margin: 0.1rem;
    padding: 4rem;
    

  }
  
  .card1 {
    border: none;
    box-sizing: border-box;
    background-color: #d0d0d0;
    box-shadow: 0px -0.5rem 1rem rgba(0, 0, 0, 0.2), 0px 0.5rem 1rem rgba(0, 0, 0, 0.2);
    margin-top: 1rem;
    grid-column: 1;
    margin: 0.05rem;
    border: solid 1px black;
    border-radius: 0.2rem;

  }
  
    
  ul {
    list-style: none;
    padding: 0;
  }

  .workXP {
    background-color: #d0d0d0;
  }

  h6 {
    background-color: #d0d0d0;
  }
  
  li {
    margin-bottom: 8px;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  li:hover {
    background-color: #e0e0e0;
  }
  
  .skillsGroup {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  

   .card2 {
    border: solid 1px black;
    box-sizing: border-box; 
    grid-column: 2;
    background-color: #d0d0d0;
    box-shadow:
    0px -0.5rem 1rem rgba(0, 0, 0, 0.2), 
    0px 0.5rem 1rem rgba(0, 0, 0, 0.2); 
    margin: 0.05rem;
    border-radius: 0.2rem;

}

.headshot {

    border-radius: 1rem;
    width: 20rem;
    margin-top: 1rem;
}

.card3 {
    border: none;
    box-sizing: border-box;
    background-color: #d0d0d0;
    box-shadow: 0px -0.5rem 1rem rgba(0, 0, 0, 0.2), 0px 0.5rem 1rem rgba(0, 0, 0, 0.2);
    grid-column: 1 / span 2;
    border: solid 1px black;
    margin: 0.05rem;
    border-radius: 0.2rem;

  }

 
  .imageSlider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden; 
    margin-top: 20px; 
    
  }
  
  .imageContainer {
    display: flex;
    position: relative;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 80px;
    gap: 16px;
    width: 200%;
    height: 100%;
    animation: slide 60s linear infinite;
    &:hover {
        animation-play-state: paused;
    }
  }
  



  img {
    max-width: 100%; 
    max-height: 200px; 
    width: auto; 
    height: auto; 
    margin: 0 5px; 
  }
  
  .navigation button {
    margin: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }

  .nextButton {
    z-index:100
  }

  .prevButton {
    z-index:100
  }

  @keyframes slide {
    0% {
      left: 0;
    }
    100% {
      left: -200%;
    }
  }
