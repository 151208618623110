@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Merienda&display=swap');

.App {
  text-align: center;
  background-image: url("./Media/background.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow: scroll;
  height: 75%;
}

.App::-webkit-scrollbar {
  display: none;
}

.App-logo {
  height: 12vmin;
  pointer-events: none;
  margin: 0 auto; /* This centers the logo */
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #777777;
  height: 15rem;
text-align: center;
  
}

.App-link {
  color: #61dafb;
}

/* ... other styles ... */


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Bebas Neue', sans-serif;
  font-family: 'Merienda', cursive;
  
}

.tile1 > h3 {
  margin-left: 12rem;
  font-size: 2rem;
  
}

.tile2 > h3 {
  font-size: 2rem;

}

.tile3 > h3 {
  font-size: 2rem;
  margin-left: -18rem;

}

.tile1,
.tile2,
.tile3 {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 70%;
  height: 18rem;
  background-color: #d0d0d0;
  margin: 1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, width 0.8s ease, height 0.8s ease;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2); 
  overflow: hidden;
}

.tile1:active{
  transform: translateY(2px);
  box-shadow: 0 0 0 transparent;
}

.tile1:hover,
.tile2:hover,
.tile3:hover {
  background-color: #777777;
  width: 90%;
  height: 23rem;

}

button {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  cursor: pointer;
border: none;
background-color: transparent;
}

button:active {
  transform: translateY(2px); /* Move the button down slightly */
  box-shadow: 0 0 0 transparent;
}



.tileImage {
  width: 100px; /* Adjust as needed */
  margin-left: 1rem; /* Add spacing between text and image */
}

img {
  height: 80%;
  width: auto;
  border-radius: 1rem;
  transition: transform 0.8s ease, opacity 0.8s ease; /* Smooth transition */

  
}


.headshot {
  transform: translateX(50%);
}

.messageGen {
  transform: translateX(100%);

}



.rugbyThrow {
opacity: 0%;
transition: opacity 0.8s ease, transform 0.8s ease;
}

.tile2:hover >.rugbyThrow {
  opacity: 100%;
  transform: translateX(-10%);
}

.playlistForge {
  opacity: 0%;
  width: auto;
  transition: opacity 0.8s ease, transform 0.8s ease;
  }
  
  .tile1:hover >.playlistForge {
    opacity: 100%;
    transform: translateX(-30%);
  }

.contact {
  transform: translateX(230%);

}

  .sent {
    opacity: 0%;
    transition: opacity 0.8s ease, transform 0.8s ease;
    }
    
    .tile3:hover >.sent {
      opacity: 100%;
      transform: translateX(110%);
    }

.tile1:hover > img,
.tile2:hover > img,
.tile3:hover > img {
 
  transform: translateX(170%);
  opacity: 15%;
  

}

.footer {
  height: 15rem;
}
.reset-link {
  all: unset;
}

a {
  color: black;
  text-decoration: none;
}

.buttonDiv {
background-color: transparent;}
