.messageGenContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.generator {
    background-color: #a8a8a8;
width: 98%;
border-radius: 1rem;
min-height:12rem;

}

/* CSS */
.buttonGen {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 20%;
}

.buttonGen:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* .button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
} */

iframe {
    width: 100%; /* Adjust the width as needed */
    height: auto; /* Ensures the aspect ratio remains constant */
    min-height: 40rem;
    padding: 1rem;
    

    
  }

  .iframe::-webkit-scrollbar {
    display: none;
  }

  .iframe {
    -ms-overflow-style: none; 
    scrollbar-width: none;  
  }

  .iframeContainer {
background-color: #d0d0d0;
    border-radius: 1rem;
margin-bottom: 2rem;
margin: 1rem;
  }

  .message {
    font-size: 2em;
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    top: -2px;
  left: -2px;
  background-size: 400%;

    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; 
    transition: opacity 0.3s ease-in-out;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    z-index: -1;

  }
  
  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  